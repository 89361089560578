import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { FiInfo } from "react-icons/fi";
import { Counter, LikeButton, RegVideo, Video } from "../components/Complete";
import { Link } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <section className="mdx-page">
      <p><em parentName="p">{`I'm baby humblebrag neutra taiyaki af vice bespoke locavore fanny pack`}</em>{` ramps vexillologist succulents. Normcore etsy pour-over adaptogen skateboard fashion axe, bushwick food truck beard lumbersexual master cleanse actually deep v. `}<strong parentName="p">{`Retro cardigan raw denim franzen kickstarter you probably haven't heard of them literally`}</strong>{` aesthetic snackwave four dollar toast. Keffiyeh synth umami, helvetica yr flexitarian jean shorts banh mi cardigan leggings letterpress paleo intelligentsia narwhal.`}</p>
      <h1>{`Gatsby MDX`}</h1>
      <h3>{`Random Heading`}</h3>
      <h2>{`More Examples`}</h2>
      <div className="code">
        <pre><code parentName="pre" {...{
            "className": "language-js"
          }}>{`const firstName = "john"
const lastName = "smith"
`}</code></pre>
 </div>
      <div className="nice-text">
        <h3>{`Nice Text`}</h3>
        <FiInfo className="nice-text-icon" mdxType="FiInfo"></FiInfo>
        <p>{`I'm baby prism everyday carry post-ironic jean shorts venmo health goth, migas pok pok vape beard umami. Poutine plaid before they sold out cronut messenger bag, echo park hexagon tumblr +1 quinoa mumblecore paleo. Activated charcoal drinking vinegar VHS gentrify put a bird on it tousled.`}</p>
      </div>
      <h2>{`React Components`}</h2>
      <Counter mdxType="Counter" />
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`import React from "react"
import styled from "styled-components"
import { AiOutlineLike } from "react-icons/ai"
const LikeButton = () => {
  const [value, setValue] = React.useState(0)
  return (
    <Wrapper>
      <div>
        <button onClick={() => setValue(value + 1)}>
          <AiOutlineLike />
        </button>
        <p>
          Liked the post?
          <br />
          Click thumbs up few times
        </p>
      </div>
      <span>+{value}</span>
    </Wrapper>
  )
}
`}</code></pre>
      <LikeButton mdxType="LikeButton" />
      <h2>{`Videos`}</h2>
      <RegVideo mdxType="RegVideo" />
      <Video mdxType="Video" />
      <h2>regular heading</h2>
      <h2 title> nice title </h2>
      <h4>inline code</h4>
      <p><inlineCode parentName="p">{`console.log('hello there')`}</inlineCode></p>
      <h4> blockquote element </h4>
      <blockquote display="default">
  I'm baby prism everyday carry post-ironic jean shorts venmo health goth, migas
      </blockquote>
      <blockquote display="info">
  I'm baby prism everyday carry post-ironic jean shorts venmo health goth, migas
      </blockquote>
      <blockquote display="warning">
  I'm baby prism everyday carry post-ironic jean shorts venmo health goth, migas
      </blockquote>
      <blockquote>
  I'm baby gluten-free lo-fi tumblr, bicycle rights edison bulb green juice
  ennui next level viral jean shorts. Food truck godard street art migas fixie
  shaman paleo chia single-origin coffee poke pug portland flannel.
      </blockquote>
      <blockquote>
        <p parentName="blockquote">{`hello there`}</p>
      </blockquote>
      <h4>{`Javascript`}</h4>
      <pre><code parentName="pre" {...{
          "className": "language-js"
        }}>{`// comments
const name = "john"
const channel = "coding addict"

function featuredProducts(data) {
  return data.filter(item => {
    return item.featured === true
  })
}
featuredProducts()
`}</code></pre>
      <h4>{`JSX`}</h4>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`import React from "react"

const Counter = () => {
  const [count, setCount] = React.useState(0)
  return (
    <section className="counter">
      <h4>show some love to MDX</h4>
      <h4>likes {count}</h4>
      <button className="btn btn-danger" onClick={() => setCount(count + 1)}>
        i like mdx
      </button>
    </section>
  )
}
export default Counter
`}</code></pre>
      <h4>{`HTML`}</h4>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<h1>hello world</h1>
<p class="random">random text</p>
<section class="contact">
  <div class="title">
    <h1>contact us</h1>
  </div>
</section>
`}</code></pre>
      <h4>{`CSS`}</h4>
      <pre><code parentName="pre" {...{
          "className": "language-css",
          "metastring": "file=App.js",
          "file": "App.js"
        }}>{`max-width: 500px;
border-radius: var(--radius);
padding: 1rem 1.5rem;
background: var(--clr-grey-10);
text-align: center;
`}</code></pre>
      <article>this is aside</article>
      <Link to="/posts" className="btn center-btn" mdxType="Link"> All Products</Link>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      